import React from "react";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { Button, Grid, Paper } from "@material-ui/core";
import PropTypes from "prop-types";

export const Form = ({touched,errors, classes, handleSubmit, handleChange, isSubmitting }) => {
  return (
    <Grid item xs={11} sm={6} md={3}>
      <Paper className={classes.paper}>
        <img className={classes.logo} src="/images/logo.png" alt="logo" />
        <form method="post" className={classes.form} onSubmit={handleSubmit} noValidate>
          <p>
            Por favor digite e confirme seu e-mail, e enviaremos um link para
            você alterar a sua senha
          </p>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            onChange={handleChange}
            error={touched.email && Boolean(errors.email)}
            helperText={errors.email && touched.email && errors.email}
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isSubmitting}
          >
            Enviar
          </Button>
          <Grid item lg>
            <Link to="/login" variant="body2">
              Voltar
            </Link>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
};

Form.propTypes = {
  classes: PropTypes.object.isRequired
};

export default Form;
