import React from "react";
import {
  Button,
  Grid,
  Paper,
  Container,
  Typography,
  TextField,
  Select,
  MenuItem,
  OutlinedInput,
  InputLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox
} from "@material-ui/core";
import PropTypes from "prop-types";
import { renderAddressFormatter } from "../../../_helpers/utils";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
var NumberFormat = require("react-number-format");
const useStyles = makeStyles(theme => ({
  root: {},
  formControl: {
    margin: theme.spacing(3)
  }
}));
function CheckboxesGroup({ handleChange, errors, touched }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    garantor: false,
    deposit: false,
    income_receipt: false,
    credit_card: false
  });
  const handleChange2 = name => event => {
    setState({ ...state, [name]: event.target.checked });
    handleChange(event);
  };

  const { garantor, deposit, income_receipt, credit_card } = state;

  return (
    <div className={classes.root}>
      <Typography variant="inherit">Possibilidades de garantias</Typography>
      <FormControl component="fieldset" className={classes.formControl}>
        {errors.guarantees && (
          <FormHelperText error>{errors.guarantees}</FormHelperText>
        )}
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={garantor}
                onChange={handleChange2("garantor")}
                value="garantor"
                name="guarantees.guarantor"
                color="primary"
              />
            }
            label="Tenho Fiador"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={deposit}
                onChange={handleChange2("deposit")}
                value="deposit"
                name="guarantees.deposit"
                color="primary"
              />
            }
            label="Caução"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={income_receipt}
                onChange={handleChange2("income_receipt")}
                value="income_receipt"
                name="guarantees.income_receipt"
                color="primary"
              />
            }
            label="Comprovante de Renda"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={credit_card}
                onChange={handleChange2("credit_card")}
                value="credit_card"
                name="guarantees.credit_card"
                color="primary"
              />
            }
            label="Cartão de Crédito"
          />
        </FormGroup>
      </FormControl>
    </div>
  );
}
export const Form = props => {
  const {
    handleBlur,
    errors,
    touched,
    values,
    classes,
    handleChange,
    handleSubmit
  } = props;
  const { listing } = values;
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  // const [income, setIncome] = React.useState("");
  // function handleChange2(event) {
  //   setIncome(event.target.value);
  //   handleChange(event);
  // }

  return (
    <Container component="main" maxWidth="md">
      <form
        method="post"
        className={classes.form2}
        onSubmit={handleSubmit}
        noValidate
      >
        <Paper className={classes.paper}>
          {listing ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h6">
                  Imóvel
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Link
                  href={`https://app.zimobi.com.br/listings/view/${listing.property.id}`}
                  color="inherit"
                  className={classes.link}
                >
                  <img
                    className={classes.mainPicture}
                    alt="Foto Anúncio"
                    src={listing && listing.images && listing.images[0].url}
                  />
                </Link>
              </Grid>
              <Grid item md={8} xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography gutterBottom variant="h6">
                      {listing && listing.property.property_type.label}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      {listing &&
                        renderAddressFormatter(listing.property.address)}
                    </Typography>
                    <br />
                    <Typography variant="h6">Total</Typography>
                    <Typography variant="h5">
                      <NumberFormat
                        value={listing && listing.total_price_in_cents / 100}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"R$"}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            "Anúncio não encontrado"
          )}
        </Paper>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                Sobre você
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              {/* <Avatar
                alt="Remy Sharp"
                src="https://material-ui.com/static/images/avatar/1.jpg"
                className={classes.bigAvatar}
              /> */}
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.full_name && Boolean(errors.full_name)}
                helperText={
                  errors.full_name && touched.full_name && errors.full_name
                }
                value={values.full_name}
                label="Nome Completo"
                name="full_name"
                autoComplete="full_name"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <NumberFormat
                variant="outlined"
                required
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.phone && Boolean(errors.phone)}
                helperText={errors.phone && touched.phone && errors.phone}
                value={values.phone}
                fullWidth
                name="phone"
                label="Telefone"
                type="tel"
                autoComplete="phone"
                customInput={TextField}
                format="(##) #####-####"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={errors.email && touched.email && errors.email}
                value={values.email}
                label="Email"
                name="email"
                type="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                variant="outlined"
                fullWidth
                rows={4}
                multiline={true}
                onChange={handleChange}
                label="Sobre"
                helperText="Conte um pouco sobre você"
                name="about"
                autoComplete="about"
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                Dados Pessoais
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <NumberFormat
                variant="outlined"
                required
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.rg && Boolean(errors.rg)}
                helperText={errors.rg && touched.rg && errors.rg}
                value={values.rg}
                label="RG"
                name="rg"
                autoComplete="rg"
                customInput={TextField}
                format="#############"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <NumberFormat
                variant="outlined"
                required
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.cpf && Boolean(errors.cpf)}
                helperText={errors.cpf && touched.cpf && errors.cpf}
                value={values.cpf}
                label="CPF"
                name="cpf"
                autoComplete="cpf"
                customInput={TextField}
                format="###.###.###-##"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.profession && Boolean(errors.profession)}
                helperText={
                  errors.profession && touched.profession && errors.profession
                }
                value={values.profession}
                label="Profissão"
                name="profession"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
                fullWidth
                variant="outlined"
                error={touched.income && Boolean(errors.income)}
              >
                <InputLabel ref={inputLabel} htmlFor="income-label-placeholder">
                  Faixa de Renda
                </InputLabel>
                <Select
                  onChange={handleChange}
                  onBlur={handleChange}
                  error={touched.income && Boolean(errors.income)}
                  value={values.income}
                  input={
                    <OutlinedInput
                      labelWidth={labelWidth}
                      name="income"
                      id="income-label-placeholder"
                    />
                  }
                  displayEmpty={false}
                  name="income"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={1}>Entre R$ 1000,00 e R$ 2500,00</MenuItem>
                  <MenuItem value={2}>Entre R$ 2500,00 e R$ 5000,00</MenuItem>
                  <MenuItem value={3}>Mais de R$ 5000,00</MenuItem>
                </Select>
                {touched.income && Boolean(errors.income) && (
                  <FormHelperText error>
                    {errors.income && touched.income && errors.income}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                Aluguel
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="inherit">
                Mora de aluguel atualmente?
              </Typography>
              <FormControl
                className={classes.formControl}
                fullWidth
                variant="outlined"
                error={
                  touched.is_currently_renter &&
                  Boolean(errors.is_currently_renter)
                }
              >
                <RadioGroup
                  aria-label="is_currently_renter"
                  name="is_currently_renter"
                  onChange={handleChange}
                  onBlur={handleChange}
                  error={
                    Boolean(errors.is_currently_renter) &&
                    touched.is_currently_renter
                  }
                  value={values.is_currently_renter}
                  className={classes.options}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio color="primary" />}
                    label="Sim"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio color="primary" />}
                    label="Não"
                    labelPlacement="start"
                  />
                </RadioGroup>
                {touched.income && Boolean(errors.income) && (
                  <FormHelperText error>
                    {errors.income && touched.income && errors.income}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                onChange={handleChange}
                onBlur={handleChange}
                error={
                  touched.rental_reference && Boolean(errors.rental_reference)
                }
                helperText={
                  (errors.rental_reference &&
                    touched.rental_reference &&
                    errors.rental_reference) ||
                  "Imobiliária ou proprietário"
                }
                value={values.rental_reference}
                label="Referência de Aluguel"
                name="rental_reference"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                onChange={handleChange}
                onBlur={handleChange}
                error={
                  touched.reference_contact && Boolean(errors.reference_contact)
                }
                helperText={
                  (errors.reference_contact &&
                    touched.reference_contact &&
                    errors.reference_contact) ||
                  "Telefone ou e-mail"
                }
                value={values.reference_contact}
                fullWidth
                name="reference_contact"
                label="Contato de referência"
                autoComplete="reference_contact"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CheckboxesGroup {...props} />
            </Grid>
          </Grid>
        </Paper>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          float="right"
          className={classes.submit}
          // disabled={isSubmitting}
        >
          Criar Perfil
        </Button>
      </form>
    </Container>
  );
};

Form.propTypes = {
  classes: PropTypes.object.isRequired
};

export default Form;
