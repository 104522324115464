import React from "react";

export const renderAddressFormatter = address => {
  if (!address) {
    return "";
  }
  const { street, complement, neighbourhood, number, city } = address;
  var part1 = `${street} ${number || ""}, ${complement || " "}`;
  var part2 = `${neighbourhood}, ${city}`;
  return (
    <div>
      <div>{part1}</div>
      <div>{part2}</div>
    </div>
  );
};
