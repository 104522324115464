import React, { Component } from "react";
import { Formik } from "formik";
import { Form } from "./components/form";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import LoadingMessage from "../../_components/Shared/LoadingMessage";
import { changePassword } from "../../_actions";
import { connect } from "react-redux";
const styles = theme => ({
  paper: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1)
  },
  logo: {
    marginBottom: theme.spacing(4)
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    textAlign: "center"
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
});
class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  submit = values => {
    console.debug(values);
  };
  render() {
    const values = { email: "" };
    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh", backgroundColor: "#F0F0F0" }}
      >
        {this.props.isFetching ? (
          <LoadingMessage title={"Aguarde"} />
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={values}
            validate={values => {
              // console.log(values);
            }}
            onSubmit={(values, actions) => {
              console.log(values);
              actions.setSubmitting(false);
              this.props.changePassword(values, "token");
            }}
            render={props => (
              <Form {...props} {...this.props} values={values} />
            )}
          />
        )}
      </Grid>
    );
  }
}
const mapStateToProps = state => ({
  isFetching: state.loadingReducer["CHANGE_PASSWORD"]
});
export default connect(
  mapStateToProps,
  {
    changePassword
  }
)(withStyles(styles)(ChangePasswordForm));
