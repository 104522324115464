import "../_helpers/serviceConfig";
class ListingService {
  async getListingById(listingId) {
    return window.axios
      .get(`/listings/${listingId}`)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  async addListingApplication(user_id, listingId) {
    return window.axios
      .post(`/listings/${listingId}/applications`, { user_id })
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
export default ListingService;
