import React from "react";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { Button, Grid, Paper, Container } from "@material-ui/core";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import LoadingMessage from "../../../_components/Shared/LoadingMessage";

export const Form = ({
  values,
  loading,
  errors,
  touched,
  classes,
  handleSubmit,
  handleBlur,
  handleChange,
  isSubmitting
}) => {
  return (
    <Container component="main" maxWidth="sm">
      <Paper className={classes.paper}>
        <img className={classes.logo} src="/images/logo.png" alt="logo" />
        <form
          method="post"
          className={classes.form}
          onSubmit={handleSubmit}
          noValidate
        >
          {loading ? (
            <Grid item md={12} xs={12}><LoadingMessage shallow={true} />   </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  value={values.full_name}
                  required
                  fullWidth
                  onChange={handleChange}
                  label="Nome Completo"
                  name="full_name"
                  autoComplete="full_name"
                  onBlur={handleBlur}
                  error={touched.full_name && Boolean(errors.full_name)}
                  helperText={
                    errors.full_name && touched.full_name && errors.full_name
                  }
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <NumberFormat
                  variant="outlined"
                  required
                  onChange={handleChange}
                  value={values.phone}
                  fullWidth
                  name="phone"
                  label="Telefone"
                  type="tel"
                  autoComplete="phone"
                  customInput={TextField}
                  onBlur={handleBlur}
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={errors.phone && touched.phone && errors.phone}
                  format="(##) #####-####"
                />
              </Grid>
              <Grid item lg={6} xs={12} md={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  onChange={handleChange}
                  value={values.email}
                  label="Email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={errors.email && touched.email && errors.email}
                />
              </Grid>
              <Grid item lg={6} xs={12} md={6}>
                <TextField
                  variant="outlined"
                  required
                  onChange={handleChange}
                  label="Confirme seu Email"
                  name="emailConfirmation"
                  type="email"
                  value={values.emailConfirmation}
                  onBlur={handleBlur}
                  error={
                    touched.emailConfirmation &&
                    Boolean(errors.emailConfirmation)
                  }
                  helperText={
                    errors.emailConfirmation &&
                    touched.emailConfirmation &&
                    errors.emailConfirmation
                  }
                  fullWidth
                />
              </Grid>
              <Grid item lg xs={12}>
                <TextField
                  variant="outlined"
                  required
                  onChange={handleChange}
                  label="Senha"
                  id="password"
                  type="password"
                  name="password"
                  autoComplete="password"
                  value={values.password}
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                  helperText={
                    errors.password && touched.password && errors.password
                  }
                  fullWidth
                />
              </Grid>
              <Grid item lg xs={12}>
                <TextField
                  variant="outlined"
                  required
                  onChange={handleChange}
                  label="Confirme sua Senha"
                  type="password"
                  value={values.confirmPassword}
                  name="confirmPassword"
                  onBlur={handleBlur}
                  error={
                    touched.confirmPassword && Boolean(errors.confirmPassword)
                  }
                  helperText={
                    errors.confirmPassword &&
                    touched.confirmPassword &&
                    errors.confirmPassword
                  }
                  fullWidth
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isSubmitting}
                >
                  Cadastrar
                </Button>
                <Grid item>
                  <Link to="/login" variant="body2">
                    Ja tenho uma conta
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          )}
        </form>
      </Paper>
    </Container>
  );
};

Form.propTypes = {
  classes: PropTypes.object.isRequired
};

export default Form;
