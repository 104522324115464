import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
const styles = theme => ({
  paper: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1)
  },
  logo: {
    marginBottom: theme.spacing(4)
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    textAlign: "center"
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
});
class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh", backgroundColor: "#F0F0F0" }}
      >
        Bem vindo a Zimobi
      </Grid>
    );
  }
}
const mapStateToProps = state => ({
  // isLoading: state.loadingReducer["LOGIN"]
});
export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(HomePage));
