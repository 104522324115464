import React, { Component } from "react";
import { Form } from "./components/form";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import LoadingMessage from "../../_components/Shared/LoadingMessage";
import { getListingById, signup } from "../../_actions";
import { withFormik } from "formik";
import * as Yup from "yup";
let mandatory = "Campo Obrigatório";

const SignUpSchema = Yup.object().shape({
  rg: Yup.string().required(mandatory),
  cpf: Yup.string().required(mandatory),
  full_name: Yup.string().required(mandatory),
  phone: Yup.string().required(mandatory),
  profession: Yup.string().required(mandatory),
  rental_reference: Yup.string().required(mandatory),
  reference_contact: Yup.string().required(mandatory),
  is_currently_renter: Yup.string().required(mandatory),
  guarantees: Yup.object({
    deposit: Yup.boolean(),
    guarantor: Yup.boolean(),
    income_receipt: Yup.boolean(),
    credit_card: Yup.boolean()
  }).test("at least one true", null, obj => {
    if (obj.deposit || obj.guarantor || obj.income_receipt || obj.credit_card) {
      return true; // everything is fine
    }
    return new Yup.ValidationError(
      "Você deve selecionar pelo menos uma opção",
      null,
      "guarantees"
    );
  }),
  income: Yup.string().required(mandatory),
  email: Yup.string()
    .email("Email inválido")
    .required(mandatory)
  // password: Yup.string().required(mandatory),
  // emailConfirmation: Yup.string()
  //   .oneOf([Yup.ref("email"), null], "Emails não coincidem")
  //   .required(mandatory),
  // confirmPassword: Yup.string()
  //   .oneOf([Yup.ref("password"), null], "Senhas não coincidem")
  //   .required(mandatory)
});

const styles = theme => ({
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    // display: "table",
    flexDirection: "column"
  },
  mainPicture: {
    width: "auto",
    height: "200px"
  },
  options: {
    display: "table"
  },
  avatar: {
    margin: theme.spacing(1)
  },
  logo: {
    marginBottom: theme.spacing(4)
  },
  form: {
    width: "100%" // Fix IE 11 issue.
  },
  bigAvatar: {
    margin: 10,
    width: 100,
    height: 100
  },
  submit: {
    margin: theme.spacing(2),
    float: "right"
  }
});
export class SignUpWithInterest extends Component {
  componentWillMount() {
    this.props.getListingById(this.props.match.params.listingId);
  }
  render() {
    const EnhancedForm = withFormik({
      mapPropsToValues: () => ({
        ...this.props.listing,
        full_name: "Diego",
        phone: "41999403068",
        email: "diegoalexh@gmail.com",
        password: "123456",
        rg: "98852450",
        cpf: "06725789994",
        profession: "Analista",
        income: "1",
        rental_reference: "None",
        reference_contact: "None",
        is_currently_renter: "1"
      }),
      validationSchema: SignUpSchema,
      handleSubmit: (values, { setSubmitting }) => {
        console.log(values);
        setSubmitting(false);
        this.props.signup(values);
      },
      displayName: "Form" // helps with React DevTools
    })(Form);
    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh", backgroundColor: "#F0F0F0" }}
      >
        {this.props.isLoading ? (
          <LoadingMessage />
        ) : (
          <EnhancedForm classes={this.props.classes} />
        )}
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.loadingReducer["GET_LISTING"],
  listing: state.others || void 0
});
export default connect(
  mapStateToProps,
  {
    getListingById,
    signup
  }
)(withStyles(styles)(SignUpWithInterest));
