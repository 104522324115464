import UserService from "../_services/user.service";
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  GET_PROFILE_REQUEST,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  LOGOUT_REQUEST,
  GET_LISTING_REQUEST,
  GET_LISTING_FAILURE,
  GET_LISTING_SUCCESS,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE
} from "./types";
import { history } from "../_helpers/history";
import { alertActions } from "./alert.actions";
import ListingService from "../_services/listing.service";
let userService = new UserService();
let listingService = new ListingService();
export const getSelf = () => dispatch => {
  dispatch({ type: GET_PROFILE_REQUEST });
  userService
    .getSelf()
    .then(response => {
      dispatch(success(response.data));
    })
    .catch(error => dispatch(failure(error)));
  const success = data => ({ type: GET_PROFILE_SUCCESS, payload: data });
  const failure = error => ({
    type: GET_PROFILE_FAILURE,
    payload: "Não foi possível obter o perfil"
  });
};

export const updateProfile = values => dispatch => {
  dispatch({ type: UPDATE_PROFILE_REQUEST });
  userService
    .updateProfile(values)
    .then(response => {
      dispatch(alertActions.success("Perfil Atualizado!"));
      dispatch(success(response.data));
    })
    .catch(error => {
      dispatch(failure(error));
    });
  const success = data => ({ type: UPDATE_PROFILE_SUCCESS, payload: data });
  const failure = error => ({
    type: UPDATE_PROFILE_FAILURE,
    payload: "Não foi possível atualizar o perfil"
  });
};

export const login = body => dispatch => {
  dispatch({ type: LOGIN_REQUEST });
  userService
    .login(body)
    .then(data => {
      dispatch(success(data));
    })
    .then(() => history.push("/home"))
    .catch(error => dispatch(failure(error)));
  const success = data => ({ type: LOGIN_SUCCESS, payload: data });
  const failure = error => ({
    type: LOGIN_FAILURE,
    payload: "Usuário ou senha inválidos"
  });
};
export const logout = () => dispatch => {
  dispatch({ type: LOGOUT_REQUEST });
  history.push("/login");
};
export const changePassword = ({ email, password, token }) => dispatch => {
  dispatch({ type: CHANGE_PASSWORD_REQUEST });
  userService
    .changePassword(email, password, token)
    .then(data => {
      dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: data });
    })
    .then(() => history.push("/profile"))
    .catch(error =>
      dispatch({
        type: CHANGE_PASSWORD_FAILURE,
        payload: "Erro ao atualizar senha, tente novamente mais tarde"
      })
    );
};
export const forgotPassword = email => dispatch => {
  dispatch({ type: FORGOT_PASSWORD_REQUEST });
  userService
    .forgotPassword(email)
    .then(data => {
      dispatch(sent(data));
      dispatch({ type: FORGOT_PASSWORD_SUCCESS });
    })
    .then(() => history.push("/login"))
    .catch(error => {
      dispatch({ type: FORGOT_PASSWORD_FAILURE });
      dispatch(sent(error));
      history.push("/login");
    });
  const sent = data =>
    alertActions.success(
      "Sua solicitação foi enviada, em breve você receberá um email com o link para recuperar sua senha"
    );
};

export const getListingById = listingId => dispatch => {
  dispatch({ type: GET_LISTING_REQUEST });
  listingService
    .getListingById(listingId)
    .then(response => {
      dispatch(success(response.data));
    })
    .catch(error => {
      dispatch(failure(error));
    });

  const success = data => ({ type: GET_LISTING_SUCCESS, payload: data });
  const failure = error => ({
    type: GET_LISTING_FAILURE,
    payload: "Falha ao obter anúncio",
    error
  });
};

export const signup = body => dispatch => {
  dispatch({ type: SIGNUP_REQUEST });
  userService
    .register({
      full_name: body.full_name,
      phone: body.phone,
      email: body.email,
      password: body.password,
      role: body.role
    })
    .then(response => {
      dispatch(login(body));
      dispatch(success(response.data));
      if(body.listing && body.listing.id){
        listingService.addListingApplication(response.data.id,body.listing.id).then(console.log)
      }
    })
    .then(() => history.push("/home"))
    .catch(error => {
      dispatch(failure(error));
      dispatch(
        alertActions.error(
          error.response.data["email"] &&
            "Já existe alguem utilizando este email"
        )
      );
    });
  const success = data => ({ type: SIGNUP_SUCCESS, payload: data });
  const failure = error => ({
    type: SIGNUP_FAILURE,
    payload: "Erro ao registrar usuário",
    error
  });
};
