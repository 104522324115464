import "../_helpers/serviceConfig";
class UserService {
  constructor() {
    this.user = {
      id: 1,
      name: "Diego Alex Harmatiuk",
      email: "diegoalexh@gmail.com",
      role: "ADMIN"
    };
  }

  async updateProfile(body) {
    return window.axios
      .put(`/users/self`, JSON.stringify(body))
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  async getSelf() {
    return window.axios
      .get(`/users/self`)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
  async login({ email, password }) {
    return window.axios
      .post(`/auth`, JSON.stringify({ email, password }))
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  async forgotPassword({ email }) {
    return window.axios
      .post(`/users/password/reset_request`, JSON.stringify({ email }))
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  async changePassword(email, password, token) {
    console.log(JSON.stringify({ email, password, token }));
    return window.axios
      .put(`/users/password`, JSON.stringify(email, password, token))
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
  async register(body) {
    return window.axios
      .post(`/users`, JSON.stringify(body))
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
  handleResponse(response) {
    const data = response.data;
    if (response.status !== 201) {
      if (response.status === 401) {
        //   logout();
        // location.reload(true);
      }
      return Promise.reject(data);
    }

    return data;
  }
}
export default UserService;
