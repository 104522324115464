import React from "react";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { Button, Grid, Paper } from "@material-ui/core";
import PropTypes from "prop-types";

export const Form = ({ classes, handleSubmit, handleChange, isSubmitting }) => {
  return (
    <Grid item xs={11} sm={6} md={3}>
      <Paper className={classes.paper}>
        <img className={classes.logo} src="/images/logo.png" alt="logo" />
        <form method="post" className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            onChange={handleChange}
            id="password"
            label="Nova Senha"
            name="password"
            type="password"
            autoComplete="password"
            autoFocus
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isSubmitting}
          >
            Enviar
          </Button>
          <Grid item lg>
            <Link to="/login" variant="body2">
              Voltar
            </Link>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
};

Form.propTypes = {
  classes: PropTypes.object.isRequired
};

export default Form;
