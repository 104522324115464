import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { reducer as formReducer } from "redux-form";
import alert from "../_reducers/alert";
import others from "../_reducers";
import {loadingReducer} from "../_reducers/loader.reducer";
import { createLogger } from "redux-logger";
import asyncDispatchMiddleware from "../_middleware/dispatchMiddleware";
// import { alertActions } from "../_actions/alertActions";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const FetchInterceptor = require("fetch-interceptor");
const reduxLogger = createLogger({
  diff: true,
  collapsed: true
});
// eslint-disable-next-line
const interceptor = FetchInterceptor.register({
  onBeforeRequest(request, controller) {
    // Hook before request
  },
  onRequestSuccess(response, request, controller) {
    // Hook on response success
  },
  onRequestFailure(response, request, controller) {
    // Hook on response failure
    if (response.status === 401 || response.status === 403) {
      // alertActions.error("Acesso Negado");
    }
  }
});

export default () => {
  const rootReducer = combineReducers({
    alert,
    form: formReducer,
    others,
    loadingReducer
  });

  const buildStore = () => {
    if (process.env.NODE_ENV !== "production") {
      if (module.hot) {
        return createStore(
          rootReducer,
          composeEnhancers(applyMiddleware(thunk, reduxLogger, asyncDispatchMiddleware))
        );
      }
    } else {
      return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk,asyncDispatchMiddleware)));
    }
  };

  const store = buildStore();

  return store;
};
