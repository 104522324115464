import React, { Component } from "react";
import { Formik } from "formik";
import { Form } from "./components/form";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { signup } from "../../_actions";
import { connect } from "react-redux";
import * as Yup from "yup";
let mandatory = "Campo Obrigatório";
const SignupSchema = Yup.object().shape({
  full_name: Yup.string().required(mandatory),
  phone: Yup.string().required(mandatory),
  email: Yup.string()
    .email("Email inválido")
    .required(mandatory),
  password: Yup.string().required(mandatory),
  emailConfirmation: Yup.string().oneOf([Yup.ref("email"), null], "Emails não coincidem").required(mandatory),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Senhas não coincidem")
    .required(mandatory)
});

const styles = theme => ({
  paper: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1)
  },
  logo: {
    marginBottom: theme.spacing(4)
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    // marginTop: theme.spacing(3),
    textAlign: "center"
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
});
class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {role: "renter", full_name: "" , phone: "", email:"", emailConfirmation: "", password:"", confirmPassword:""};
  }

  render() {
    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh", backgroundColor: "#F0F0F0" }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={this.state}
          validationSchema={SignupSchema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            this.props.signup(values);
          }}
          render={props => (
            <Form {...props} {...this.props} loading={this.props.isLoading} />
          )}
        />
      </Grid>
    );
  }
}
const mapStateToProps = state => ({
  isLoading: state.loadingReducer["SIGNUP"]
});
export default connect(
  mapStateToProps,
  {
    signup
  }
)(withStyles(styles)(SignUp));
