import React from "react";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { Button, Grid, Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import LoadingMessage from "../../../_components/Shared/LoadingMessage";

export const Form = ({
  classes,
  handleSubmit,
  handleChange,
  isSubmitting,
  touched,
  errors,
  isLoading
}) => {
  return (
    <Grid item xs={11} sm={6} md={4} lg={3}>
      <Paper className={classes.paper}>
        <img className={classes.logo} src="/images/logo.png" alt="logo" />
        {isLoading ? (
          <LoadingMessage title={"Aguarde"} shallow={true} />
        ) : (
          <form
            method="post"
            className={classes.form}
            onSubmit={handleSubmit}
            noValidate
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              onChange={handleChange}
              error={touched.email && Boolean(errors.email)}
              helperText={errors.email && touched.email && errors.email}
              id="email"
              label="Email"
              name="email"
              type="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              onChange={handleChange}
              error={touched.password && Boolean(errors.password)}
              helperText={errors.password && touched.password && errors.password}
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isSubmitting}
            >
              Entrar
            </Button>
            <Grid>
              <Grid item lg>
                <Link to="/forgot-password" variant="body2">
                  Esqueceu a senha?
                </Link>
                <br />
                <br />
                <Link to="/signup" variant="body2">
                  {"Não possui uma conta? Cadastre-se"}
                </Link>
              </Grid>
            </Grid>
          </form>
        )}
      </Paper>
    </Grid>
  );
};

Form.propTypes = {
  classes: PropTypes.object.isRequired
};

export default Form;
