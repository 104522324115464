import React, { Component } from "react";
import { withFormik } from "formik";
import { ProfileForm } from "./components/form";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { getSelf, updateProfile } from "../../_actions";
import { connect } from "react-redux";
import LoadingMessage from "../../_components/Shared/LoadingMessage";
import * as Yup from "yup";
let mandatory = "Campo Obrigatório";
const ProfileSchema = Yup.object().shape({
  rg: Yup.string().required(mandatory),
  cpf: Yup.string().required(mandatory),
  full_name: Yup.string().required(mandatory),
  phone: Yup.string().required(mandatory),
  email: Yup.string()
    .email("Email inválido")
    .required(mandatory),
  password: Yup.string()
  // emailConfirmation: Yup.string()
  //   .oneOf([Yup.ref("email"), null], "Emails não coincidem")
  //   .required(mandatory),
  // confirmPassword: Yup.string()
  //   .oneOf([Yup.ref("password"), null], "Senhas não coincidem")
  //   .required(mandatory)
});

const styles = theme => ({
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    flexDirection: "column"
  },
  options: {
    display: "table"
  },
  avatar: {
    margin: theme.spacing(1)
  },
  logo: {
    marginBottom: theme.spacing(4)
  },
  form: {
    width: "100%" // Fix IE 11 issue.
  },
  bigAvatar: {
    margin: 10,
    width: 100,
    height: 100,
    cursor: 'pointer'
  },
  submit: {
    margin: theme.spacing(2),
    float: "right"
  }
});
class ProfileContainer extends Component {
  componentWillMount() {
    this.props.getSelf();
  }
  render() {
    const EnhancedForm = withFormik({
      mapPropsToValues: props => ({ ...this.props.loggedUser, income: "" }),
      validationSchema: ProfileSchema,
      handleSubmit: (values, { setSubmitting }) => {
        setSubmitting(false);
        this.props.updateProfile(values);
      },
      displayName: "ProfileForm" // helps with React DevTools
    })(ProfileForm);
    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh", backgroundColor: "#F0F0F0" }}
      >
        {this.props.isLoading ? (
          <LoadingMessage />
        ) : (
          this.props.loggedUser && <EnhancedForm {...this.props} />
        )}
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  isLoading:
    state.loadingReducer["GET_PROFILE"] ||
    state.loadingReducer["UPDATE_PROFILE"],
  loggedUser: state.others.loggedUser
});
export default connect(
  mapStateToProps,
  {
    getSelf,
    updateProfile
  }
)(withStyles(styles)(ProfileContainer));
