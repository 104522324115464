import React from "react";
import { Router, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { history } from "../_helpers/history";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import Login from "../_scenes/Login";
import ForgotPassword from "../_scenes/ForgotPassword";
import Profile from "../_scenes/Profile";
import Signup from "../_scenes/Signup";
import SignUpWithInterest from "../_scenes/Signup.1";
import ChangePassword from "../_scenes/ChangePassword";
import { alertActions } from "../_actions/alert.actions";
import PrimarySearchAppBar from "../_components/Shared/ApplicationToolBar";
import Home from "../_scenes/Home";

let previousLocation = "";
class AppRouter extends React.Component {
  constructor(props) {
    super(props);
    history.listen((location, action) => {
      if (location.pathname !== previousLocation) {
        this.props.dispatch(alertActions.clear());
      }
      previousLocation = location.pathname;
    });
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <PublicRoute
            path="/"
            component={Login}
            exact={true}
            {...this.props}
          />
          
          <PublicRoute path="/login" component={Login} exact={true} />
          <PublicRoute
            path="/forgot-password"
            component={ForgotPassword}
            exact={true}
          />
          <PublicRoute path="/signup" component={Signup} exact={true} />
          <PublicRoute
            path="/signup-with-interest/:listingId"
            component={SignUpWithInterest}
            exact={true}
          />
          <PublicRoute
            path="/change-password"
            component={ChangePassword}
            exact={true}
          ></PublicRoute>
          <PrimarySearchAppBar dispatch={this.props.dispatch}>
            <PrivateRoute
              path="/home"
              component={Home}
              exact={true}
            ></PrivateRoute>
            <PrivateRoute
              path="/profile"
              component={Profile}
              exact={true}
            ></PrivateRoute>
          </PrimarySearchAppBar>
        </Switch>
      </Router>
    );
  }
}

export default connect()(AppRouter);
