import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  GET_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_SUCCESS,
  LOGOUT_REQUEST,
  GET_LISTING_SUCCESS
} from "../_actions/types";
import { alertActions } from "../_actions/alert.actions";
export default function(state = {}, action) {
  // console.log("action type => ", action.type);
  switch (action.type) {
    case LOGOUT_REQUEST:
      sessionStorage.removeItem("access_token");
      return { ...state, loggedUser: void 0 };
    case LOGIN_SUCCESS:
      window.axios.defaults.headers.common["Authorization"] =
        action.payload.access_token;
      sessionStorage.setItem("access_token", action.payload.access_token);
      return { ...state };
    case LOGIN_FAILURE:
      action.asyncDispatch(alertActions.error(action.payload));
      return { ...state };
    case GET_PROFILE_SUCCESS:
      return { ...state, loggedUser: action.payload };
    case UPDATE_PROFILE_FAILURE:
      action.asyncDispatch(alertActions.error(action.payload));
      return { ...state };
    case UPDATE_PROFILE_SUCCESS:
      return { ...state, loggedUser: action.payload };
    case GET_LISTING_SUCCESS:
        return { ...state, listing: action.payload };
    default:
      return state;
  }
}
