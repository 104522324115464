import axios from "axios";
// axios.defaults.headers.common = { "X-Requested-With": "XMLHttpRequest" };
axios.defaults.baseURL =
  process.env.NODE_ENV !== "production"
    ? "https://zimobi-api-staging.herokuapp.com/v1"
    : "https://zimobi-api.herokuapp.com/v1";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.common["Authorization"] = sessionStorage.getItem(
  "access_token"
);

window.axios = axios;
