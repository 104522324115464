import React from "react";
import {
  Button,
  Grid,
  Paper,
  Container,
  Typography,
  TextField,
  Select,
  MenuItem,
  OutlinedInput,
  InputLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Chip,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { Form } from "formik";
import NumberFormat from "react-number-format";
import Avatar from "react-avatar";
export const ProfileForm = ({
  values,
  touched,
  errors,
  handleBlur,
  classes,
  handleChange,
  handleSubmit,
  setFieldValue
}) => {
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current && inputLabel.current.offsetWidth);
  }, []);
  const handleCapture = ({ target }) => {
    const fileReader = new FileReader();
    // const name = target.accept.includes("image") ? "images" : "videos";
    if (target.files[0]) {
      const data = new FormData();
      data.append("action", "ADD");
      data.append("param", 0);
      data.append("secondParam", 0);
      data.append("file", target.files[0]);

      fileReader.readAsDataURL(target.files[0]);
      // console.log(target.files[0]);
      fileReader.onload = e => {
        // values.avatar_url = e.target.result;
        // document
        //   .getElementsByClassName("sb-avatar__image")[0]
        //   .setAttribute("src", e.target.result);
        window.axios
          .put(`${window.axios.defaults.baseURL}/files/s3/upload`, data, {
            headers: {
              "Content-Type": "multipart/form-data;"
            }
          })
          .then(response => {
            // document
            //   .getElementsByClassName("sb-avatar__image")[0]
            //   .setAttribute("src", response.data && response.data.fileUrl);
            setFieldValue("avatar_url", response.data.fileUrl);
          });
      };
    }
  };

  // const { getFieldProps, handleChange, handleSubmit } = useFormik({
  //   initialValues: initialValues,
  //   validate: values => {},
  //   onSubmit: values => handleFormSubmition(values)
  // });
  // eslint-disable-next-line
  // const [fullName, metadataFullName] = getFieldProps("full_name", "text"); // eslint-disable-next-line
  // const [phone, metadataPhone] = getFieldProps("phone", "text"); // eslint-disable-next-line
  // const [email, metadataEmail] = getFieldProps("email", "email"); // eslint-disable-next-line
  // const [avatarUrl, metadataAvatarUrl] = getFieldProps("avatar_url", "text"); // eslint-disable-next-line
  // // eslint-disable-next-line
  // const [rg, metadataRg] = getFieldProps("rg", "text"); // eslint-disable-next-line
  // const [cpf, metadataCpf] = getFieldProps("cpf", "text");
  // // eslint-disable-next-line
  // const [role, metadataRole] = getFieldProps("role", "text");
  // // eslint-disable-next-line
  // const [password, metadataPassword] = getFieldProps("password", "password");
  return (
    <Container component="main" maxWidth="md">
      <Form
        method="post"
        onSubmit={handleSubmit}
        noValidate
      >
        <Paper className={classes.paper}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                Sobre você
              </Typography>
            </Grid>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              {/* <Avatar
                alt="Remy Sharp"
                src={values && values.avatar_url}
                className={classes.bigAvatar}
              /> */}
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="contained-button-file"
                onChange={handleCapture}
                multiple
                type="file"
              />
              <label htmlFor="contained-button-file">
                {/* <Avatar
                  src={values && values.avatar_url}
                  className={classes.bigAvatar}
                /> */}
                <Avatar
                  name={values.full_name}
                  round={true}
                  id="avatar-image"
                  maxInitials={2}
                  src={values && values.avatar_url}
                />
              </label>
              <Chip label={values && values.role} />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Nome Completo"
                name="full_name"
                value={values.full_name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.full_name && Boolean(errors.full_name)}
                helperText={
                  errors.full_name && touched.full_name && errors.full_name
                }
                autoComplete="full_name"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <NumberFormat
                variant="outlined"
                value={values.phone}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.phone && Boolean(errors.phone)}
                helperText={errors.phone && touched.phone && errors.phone}
                fullWidth
                name="phone"
                label="Telefone"
                type="phone"
                autoComplete="phone"
                customInput={TextField}
                format="(##) #####-####"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                variant="outlined"
                required
                fullWidth
                label="E-mail"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={errors.email && touched.email && errors.email}
                autoComplete="email"
              />
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                label="Nova Senha"
                value={values.password}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                helperText={
                  errors.password && touched.password && errors.password
                }
                fullWidth
                type={"password"}
                autoComplete="password"
              />
            </Grid>
            <Grid item xs={12} md={6} justify="flex-end">
              <Button
                type="button"
                variant="contained"
                color="secondary"
                disabled
              >
                Atualizar Senha
              </Button>
            </Grid> */}
            {/* <Grid item xs={12} md={12} hidden>
              <TextField
                variant="outlined"
                required
                fullWidth
                rows={8}
                multiline={true}
                label="Sobre"
                helperText="Conte um pouco sobre você"
                {...about}
              />
            </Grid> */}
          </Grid>
        </Paper>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                Dados Pessoais
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <NumberFormat
                variant="outlined"
                required
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.rg && Boolean(errors.rg)}
                helperText={errors.rg && touched.rg && errors.rg}
                value={values.rg}
                label="RG"
                name="rg"
                autoComplete="rg"
                customInput={TextField}
                format="#############"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <NumberFormat
                variant="outlined"
                required
                fullWidth
                value={values.cpf}
                label="CPF"
                name="cpf"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.cpf && Boolean(errors.cpf)}
                helperText={errors.cpf && touched.cpf && errors.cpf}
                customInput={TextField}
                format="###.###.###-##"
              />
            </Grid>
            <Grid item xs={12} md={6} hidden>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Profissão"
                value={values.profession}
                helperText="Qual sua profissão atual"
              />
            </Grid>
            <Grid item xs={12} md={6} hidden>
              <FormControl variant="outlined" fullWidth>
                <InputLabel ref={inputLabel} htmlFor="outlined-age-simple">
                  Faixa de Renda
                </InputLabel>
                <Select
                  onChange={handleChange}
                  value={values.income}
                  input={
                    <OutlinedInput
                      labelWidth={labelWidth}
                      name="income"
                      id="outlined-age-simple"
                    />
                  }
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={1}>Entre R$ 1000,00 e R$ 2500,00</MenuItem>
                  <MenuItem value={2}>Entre R$ 2500,00 e R$ 5000,00</MenuItem>
                  <MenuItem value={3}>Mais de R$ 5000,00</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.paper} hidden>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                Aluguel
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="inherit">
                Mora de aluguel atualmente?
              </Typography>
              <RadioGroup
                aria-label="gender"
                name="gender2"
                value={values.income}
                onChange={handleChange}
                className={classes.options}
              >
                <FormControlLabel
                  value="female"
                  control={<Radio color="primary" />}
                  label="Sim"
                  labelPlacement="start"
                />
                <FormControlLabel
                  value="male"
                  control={<Radio color="primary" />}
                  label="Não"
                  labelPlacement="start"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                onChange={handleChange}
                label="Referência de Aluguel"
                name="rental_reference"
                autoComplete="rental_reference"
                helperText="Imobiliária ou proprietário"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                onChange={handleChange}
                fullWidth
                helperText="Telefone ou e-mail"
                name="reference_contact"
                label="Contato de referência"
                autoComplete="reference_contact"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography gutterBottom variant="inherit">
                Possibilidades de garantias{" "}
                <small>Você pode selecionar mais de um</small>
              </Typography>
              <RadioGroup
                aria-label="gender"
                name="gender2"
                onChange={handleChange}
                className={classes.options}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange("checkedB")}
                      value="checkedB"
                      color="primary"
                    />
                  }
                  label="Tenho Fiador"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange("checkedB")}
                      value="checkedB"
                      color="primary"
                    />
                  }
                  label="Caução"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange("checkedB")}
                      value="checkedB"
                      color="primary"
                    />
                  }
                  label="Comprovante de Renda"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange("checkedB")}
                      value="checkedB"
                      color="primary"
                    />
                  }
                  label="Cartão de Crédito"
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.paper}>
          <Grid
            container
            spacing={2}
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Button
              type="button"
              variant="contained"
              color="secondary"
              float="right"
              disabled
              className={classes.submit}
            >
              Compartilhar Perfil
            </Button>
          </Grid>
        </Paper>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          float="right"
          className={classes.submit}
        >
          Salvar
        </Button>
      </Form>
    </Container>
  );
};

Form.propTypes = {
  classes: PropTypes.object.isRequired
};

export default ProfileForm;
