import React from "react";
import { Paper } from "@material-ui/core";
const LoadingMessage = ({ title, subtitle, imagePath, shallow }) =>
  shallow ? (
    <div
      style={{
        padding: "1rem",
        backgroundColor: "white",
        position: "relative",
        textAlign: "center",
        color: "#9c9c9c",
        height: "auto",
        margin: "3rem"
      }}
    >
      <img
        alt={"Logo loader"}
        style={{ maxHeight: "100px" }}
        src={imagePath ? imagePath : "/images/building_loader.gif"}
      />
      {title ? <h3>{title}</h3> : <h3>Carregando</h3>}
      {subtitle && <h5>{subtitle}</h5>}
    </div>
  ) : (
    <Paper
      style={{
        padding: "1rem",
        paddingRight: "3rem",
        paddingLeft: "3rem",
        backgroundColor: "white",
        position: "relative",
        textAlign: "center",
        color: "#9c9c9c",
        height: "auto",
        margin: "3rem"
      }}
    >
      <img
        alt={"Logo loader"}
        style={{ maxHeight: "100px" }}
        src={imagePath ? imagePath : "/images/building_loader.gif"}
      />
      {title ? <h3>{title}</h3> : <h3>Carregando</h3>}
      {subtitle && <h5>{subtitle}</h5>}
    </Paper>
  );

export default LoadingMessage;
