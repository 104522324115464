import React, { Component } from "react";
import { Formik } from "formik";
import { Form } from "./components/form";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { login } from "../../_actions";
import { connect } from "react-redux";
import * as Yup from 'yup';
const LoginSchema = Yup.object().shape({
  password: Yup.string()
  .required('Insira uma senha'),
  email: Yup.string()
    .email('Email inválido')
    .required('Insira um email'),
});
const styles = theme => ({
  paper: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1)
  },
  logo: {
    marginBottom: theme.spacing(4)
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    textAlign: "center"
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
});
class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  submit = values => {
    console.debug(values);
  };
  render() {
    const values = { email: "", password: "" };
    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh", backgroundColor: "#F0F0F0" }}
      >
        <Formik
          enableReinitialize={false}
          initialValues={values}
          validationSchema={LoginSchema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            this.props.login(values);
          }}
          render={props => (
            <Form
              {...props}
              classes={this.props.classes}
              values={values}
              isLoading={this.props.isLoading}
            />
          )}
        />
      </Grid>
    );
  }
}
const mapStateToProps = state => ({
  isLoading: state.loadingReducer["LOGIN"]
});
export default connect(
  mapStateToProps,
  {
    login
  }
)(withStyles(styles)(LoginForm));
