import React, { Component } from "react";
import { Formik } from "formik";
import { Form } from "./components/form";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import LoadingMessage from "../../_components/Shared/LoadingMessage";
import { forgotPassword } from "../../_actions";
import { connect } from "react-redux";
import * as Yup from 'yup';
const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email inválido')
    .required('Insira um email'),
});
const styles = theme => ({
  paper: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1)
  },
  logo: {
    marginBottom: theme.spacing(4)
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    textAlign: "center"
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
});
export class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const values = { email: "" };
    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh", backgroundColor: "#F0F0F0" }}
      >
        {this.props.isFetching ? (
          <LoadingMessage title={"Aguarde"} />
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={values}
            validationSchema={ForgotPasswordSchema}
            onSubmit={(values, actions) => {
              // console.log(values);
              actions.setSubmitting(false);
              this.props.forgotPassword(values.email);
            }}
            render={props => (
              <Form {...props} {...this.props}  />
            )}
          />
        )}
      </Grid>
    );
  }
}
const mapStateToProps = state => ({
  isFetching: state.loadingReducer["FORGOT_PASSWORD"]
});
export default connect(
  mapStateToProps,
  {
    forgotPassword
  }
)(withStyles(styles)(ForgotPasswordForm));
