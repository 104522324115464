import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import "./App.css";
import AppRouter from "./_routers/AppRouter";
import { history } from "./_helpers/history";
import configureStore from "./_store/configureStore";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import registerServiceWorker from "./registerServiceWorker";
import { ToastContainer } from "react-toastify";
import { createMuiTheme } from "@material-ui/core";
import { blue, red } from "@material-ui/core/colors";
const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: red
  },
});
const store = configureStore();

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <Router history={history}>
        <AppRouter />
      </Router>
    </ThemeProvider>
  </Provider>
);
registerServiceWorker();
const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);

if (module.hot) {
  module.hot.accept("./_routers/AppRouter", () => {
    ReactDOM.render(<App />, rootElement);
  });
}
